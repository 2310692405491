@import "src/assets/styles/_functions.scss";
@import "src/assets/styles/_colors.scss";
@import "src/assets/styles/_fonts.scss";
@import "src/assets/styles/_mixins.scss";

// Custom ant form
.ant-input,
.ant-picker,
.ant-input-affix-wrapper,
.ant-select-selector {
  border-radius: 4px !important;
}
.ant-form {
  .anticon {
    color: $neutral-7;
    cursor: pointer;
  }
}

.ant-menu {
  padding: 0 8px;
}
.ant-menu-sub.ant-menu-inline {
  background-color: white;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}
.ant-menu-light {
  .ant-menu-submenu-title:active,
  .ant-menu-submenu-title:hover {
    color: $primary-normal;
    background-color: $red-1;
  }
}
.ant-menu-item a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu:not(.ant-menu-horizontal) {
  .ant-menu-item,
  .ant-menu-submenu {
    color: unset;

    &:hover {
      background-color: $red-1;
      .ant-menu-submenu-arrow,.ant-menu-submenu-title,.ant-menu-title-content{
        color: $primary-normal;
      }
    }
  }

  .ant-menu-item:hover a{
    color: $primary-normal;
  }
  
  .ant-menu-item-selected,
  .ant-menu-submenu-selected, .active {
    background-color: $red-1;

    &::after {
      border-right: none;
    }

    .ant-menu-submenu-arrow,.ant-menu-submenu-title,.ant-menu-title-content, .active{
      color: $primary-normal;
    }
  }
  .ant-menu-item-selected a, .active a{
    color: $primary-normal;
  }
}

ant-form-item-label,
.ant-form-item-label {
  padding: 0 !important;
}

.ant-form-item-label label {
  @include body1-regular;
}

.ant-form-item-explain-error {
  @include invalid-text;
}
.ant-switch-checked {
  background-color: $primary-6;
}

// spinner
nz-spin {
  margin: auto;
}
.ant-spin {
  max-height: unset !important;
  height: 100vh !important;
  position: fixed !important;

  .ant-spin-dot-item {
    background-color: $primary-normal;
  }
}
.loading-item {
  .ant-spin {
    height: 100% !important;
    position: absolute !important;
  }
}

// label
.custom-select-dropdown .ant-select-dropdown {
  min-width: pxToRem(200);
  max-width: pxToRem(200);
  .ant-select-item-option-content {
    white-space: normal;
    word-break: break-word;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 8px;
    span {
      @include body1-regular;
    }
  }
}
.fi-ascension-island {
  background-image: url("../images/flag-ascension-island.svg");
}
.custom-select-label.ant-select-single.ant-select-open
  .ant-select-selection-item {
  opacity: 0.3;
}
