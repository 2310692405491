@import "src/assets/styles/_colors.scss";
@import "src/assets/styles/_functions.scss";

@mixin btn-red {
  background-color: $primary-5;
  color: $neutral-1;
  border-radius: 4px;
}
@mixin btn-red-disabled {
  background-color: $primary-5;
  color: $neutral-1;
  border-radius: 4px;
  opacity: .4;
}
@mixin card-content {
  margin: pxToRem(24) pxToRem(32);
  padding: pxToRem(24) pxToRem(32);
  background-color: $neutral-1;
  border-radius: 8px;
}
@mixin icon-red {
  color: $primary-6;
  cursor: pointer;
}
@mixin topbar {
  padding: pxToRem(10) pxToRem(32);
  background-color: $neutral-1;
  display: flex;
  justify-content: space-between;
}
@mixin btn-cancel {
  background-color: $neutral-1;
  color: $neutral-10;
  border-color: $neutral-5;
  border-radius: 4px;
}
@mixin btn-save {
  background-color: $primary-6;
  border-color: $primary-6;
  color: $neutral-1;
  border-radius: 4px;
}
@mixin ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
