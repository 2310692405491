@import "src/assets/styles/_functions.scss";
@import "src/assets/styles/_colors.scss";
@import "src/assets/styles/_fonts.scss";
@import "src/assets/styles/_mixins.scss";

.content-table {
  max-width: 100%;
  overflow-x: auto;
}

.content-table {
  max-width: 100%;
  overflow-x: auto;
}

.actions {
  span {
    margin-right: pxToRem(10);
  }
}

.center-action {
  text-align: center !important;
}

.text-pagination {
  @include body1-regular;
}

.ant-table-thead {
  tr {
    th {
      background-color: $primary-normal;
      color: $neutral-1;
      padding: pxToRem(14);

      &:first-child {
        border-top-left-radius: 5px !important;
      }

      &:last-child {
        border-top-right-radius: 5px !important;
      }

      &.ant-table-column-sort {
        background-color: $primary-normal;
        color: $neutral-1;
      }

      &.ant-table-column-has-sorters {
        &:hover {
          background-color: $primary-normal;
        }
      }
    }
  }
}

.ant-table-tbody {
  tr {
    td {
      @include body1-regular;
      padding: pxToRem(14);
      .anticon {
        @include icon-red;
      }
    }
  }
}

.ant-pagination-item-active {
  border-color: $primary-normal;

  &:hover {
    border-color: $primary-normal;

    a {
      color: $primary-normal;
    }
  }

  a {
    color: $primary-normal;
  }
}

.ant-table-pagination.ant-pagination {
  margin-top: pxToRem(24);
  @include body1-regular;
}

.ant-pagination-prev,
.ant-pagination-next {
  .ant-pagination-item-link {
    border: none;
    &:hover {
      border-color: $primary-normal;
      color: $primary-normal;
    }
  }
}

.status-icon {
  display: flex;
  flex-direction: row;
  align-items: center;

  .circle-icon {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: $green-6 2px solid;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 5px;
    background-color: $green-6;
    color: $green-6;
  }
  .grey-icon {
    background-color: $neutral-7;
    color: $neutral-7;
    border-color: $neutral-7;
  }
}
.ant-table-wrapper {
  .ant-spin {
    height: 100% !important;
    position: absolute !important;
  }
}

.csv-button {
  @include btn-red;
}

tr.custom-table-row {
  td {
    border-bottom: 0;
  }
  td:nth-child(1){
    @include body1-bold;
  }
}
