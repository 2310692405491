@import "./colors";
@import "./functions";

@mixin h1-regular {
  font-size: pxToRem(38);
  font-weight: 400;
  line-height: pxToRem(46);
  color: $neutral-10;
}

@mixin h1-semibold {
  font-size: pxToRem(38);
  font-weight: 500;
  line-height: pxToRem(46);
  color: $neutral-10;
}

@mixin h1-bold {
  font-size: pxToRem(38);
  font-weight: 700;
  line-height: pxToRem(46);
  color: $neutral-10;
}

@mixin h2-regular {
  font-size: pxToRem(30);
  font-weight: 400;
  line-height: pxToRem(38);
  color: $neutral-10;
}

@mixin h2-medium {
  font-size: pxToRem(30);
  font-weight: 500;
  line-height: pxToRem(38);
  color: $neutral-10;
}

@mixin h2-bold {
  font-size: pxToRem(30);
  font-weight: 700;
  line-height: pxToRem(38);
  color: $neutral-10;
}

@mixin h3-regular {
  font-size: pxToRem(24);
  font-weight: 400;
  line-height: pxToRem(36);
  color: $neutral-10;
}

@mixin h3-medium {
  font-size: pxToRem(24);
  font-weight: 500;
  line-height: pxToRem(36);
  color: $neutral-10;
}

@mixin h3-bold {
  font-size: pxToRem(24);
  font-weight: 700;
  line-height: pxToRem(36);
  color: $neutral-10;
}

@mixin h4-regular {
  font-size: pxToRem(20);
  font-weight: 400;
  line-height: pxToRem(28);
  color: $neutral-10;
}

@mixin h4-medium {
  font-size: pxToRem(20);
  font-weight: 500;
  line-height: pxToRem(28);
  color: $neutral-10;
}

@mixin h4-bold {
  font-size: pxToRem(20);
  font-weight: 700;
  line-height: pxToRem(28);
  color: $neutral-10;
}

@mixin h5-regular {
  font-size: pxToRem(16);
  font-weight: 400;
  line-height: pxToRem(24);
  color: $neutral-10;
}

@mixin h5-medium {
  font-size: pxToRem(16);
  font-weight: 500;
  line-height: pxToRem(24);
  color: $neutral-10;
}

@mixin h5-bold {
  font-size: pxToRem(16);
  font-weight: 700;
  line-height: pxToRem(24);
  color: $neutral-10;
}

@mixin body1-regular {
  font-size: pxToRem(14);
  font-weight: 400;
  line-height: pxToRem(22);
  color: $neutral-10;
}

@mixin body1-medium {
  font-size: pxToRem(14);
  font-weight: 500;
  line-height: pxToRem(22);
  color: $neutral-10;
}

@mixin body1-bold {
  font-size: pxToRem(14);
  font-weight: 700;
  line-height: pxToRem(22);
  color: $neutral-10;
}

@mixin body2-regular {
  font-size: pxToRem(12);
  font-weight: 400;
  line-height: pxToRem(18);
  color: $neutral-10;
}

@mixin body2-medium {
  font-size: pxToRem(12);
  font-weight: 500;
  line-height: pxToRem(18);
  color: $neutral-10;
}

@mixin body2-bold {
  font-size: pxToRem(12);
  font-weight: 700;
  line-height: pxToRem(18);
  color: $neutral-10;
}

@mixin button-small {
  font-size: pxToRem(12);
  font-weight: 700;
  line-height: pxToRem(14.52);
  color: $neutral-10;
}

@mixin button-medium {
  font-size: pxToRem(14);
  font-weight: 700;
  line-height: pxToRem(19.94);
  color: $neutral-10;
}

@mixin button-large {
  font-size: pxToRem(16);
  font-weight: 700;
  line-height: pxToRem(19.36);
  color: $neutral-10;
}
@mixin modal-title {
  font-size: pxToRem(18);
  font-weight: 600;
  line-height: pxToRem(27);
  color: #333333;
}
@mixin invalid-text {
  font-size: pxToRem(11);
  color: $red-5;
}
