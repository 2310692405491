@import "src/assets/styles/_functions.scss";
@import "src/assets/styles/_colors.scss";
@import "src/assets/styles/_fonts.scss";
@import "src/assets/styles/_mixins.scss";

.display-none {
  display: none;
}
.mandatory {
  color: $red-5;
  margin-left: 3px;
}
.optional-field {
  color: $neutral-7;
}
.invalid-form {
  display: block;
  @include invalid-text;
  margin-bottom: 0;
}
.custom-icon {
  @include icon-red;
}
.cursor-pointer{
  cursor: pointer;
}
.icon-red-5 {
  color: $red-5 !important;
}
.ellipsis-one-line {
  @include ellipsis;
}
.card-body-none.ant-card .ant-card-body {
  display: none;
}
.custom-modal-confirm {
  width: auto !important;
  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-confirm-body .ant-modal-confirm-title {
    font-size: pxToRem(22) !important;
    font-weight: 600;
    line-height: pxToRem(36) !important;
    color: #404040;
    text-align: center;
  }

  .ant-modal-confirm-btns {
    float: none;
    text-align: center;
    margin-top: pxToRem(42);

    .ant-btn {
      border-radius: 4px;
      height: pxToRem(40);
      width: pxToRem(120);
      @include h5-regular;
    }

    .ant-btn:first-of-type {
      background-color: $neutral-1;
      border-color: $neutral-5;
    }

    .ant-btn:last-of-type {
      background-color: $primary-6;
      border-color: $primary-6;
      color: $neutral-1;
    }
  }
}

.input-readonly.ant-input,
.input-readonly.ant-input:hover,
.input-readonly.ant-input:focus {
  background: $neutral-3;
  border-color: $neutral-5;
  box-shadow: none;
  @include body1-regular;
  color: rgba(0, 0, 0, 0.25);
}

.custom-input-group-add-on {
  .ant-input-group-addon {
    &:first-child {
      width: 15%;
      padding: 0;

      .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
          .ant-select-customize-input
        )
        .ant-select-selector {
        background-color: $neutral-2;
      }
    }
    &:last-child {
      width: 32%;
    }
  }
}
